export default {
  methods: {
    subscribeUserEvents () {
      if (typeof this.$store.state.user !== 'undefined' && this.$store.state.user !== null) {
        this.$echo
          .private(`users.${this.$auth.user.id}`)
          .listen('NotificationCount', (e) => { 
              console.log(11111) 
              console.log(e) 
            })
      }
    }
  },

  mounted () {
    this.subscribeUserEvents()
  }
}
