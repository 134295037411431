<template>
  <div class="page">
    <form class="box" id="login-form">
      <div class="logo"></div>
      <div class="input-group">
        <label>Email</label>
        <input class="form-control" type="text" v-model="email">
        <span class="invalid-feedback" v-if="emailError.length > 0">{{ emailError }}</span>
      </div>
      <div class="input-group">
        <label>Password</label>
        <input class="form-control" type="password" v-model="password">
        <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
      </div>
      <button v-if="!load" class="btn submit" @click.prevent="login">Login</button>
      <div v-else class="load"><CircleLoad /></div>
    </form>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'
import Vue from 'vue'
import Echo from 'laravel-echo'
import EchoMixin from '@/mixins/echo.js'

export default {
    name: "Login",
    mixins: [
      EchoMixin
    ],
    components: {
      CircleLoad,
      Vue,
      Echo
    },
    data() {
        return {
            load: false,
            email: '',
            password: '',
            emailError: '',
            passwordError: ''
        }
    },
    methods: {
        initErrors () {
            this.emailError = ''
            this.passwordError = ''
        },
        async login() {
            this.load = true
            const that = this
            this.initErrors()
            await axios.post('auth/login', {
                email: this.email,
                password: this.password,
            })
              .then(function (response) {
                if (response.data.error) {
                  for (let key in response.data.errors) {
                    if (key === 'email' && response.data.errors[key][0]) {
                      that.emailError = response.data.errors[key][0]  
                    } else if (key === 'password' && response.data.errors[key][0]) {
                      that.passwordError = response.data.errors[key][0]
                    }
                  }
                  return
                } 
                const { token, expiresIn } = response.data
                that.$store.dispatch('setToken', { token, expiresIn })
                that.$store.dispatch('setUser')
                that.$store.dispatch('setContent')
                that.$router.push({ path: "/internal-deals" }) 
                that.setEcho(token)
              })
              .catch(function (error) {
                console.dir(error);
              })
            this.load = false
        },
        setEcho(token) {
          const echo_instance = new Echo({
            broadcaster: 'socket.io',
            //encrypted: true,
            //namespace: 'App.Events',
            host: process.env.VUE_APP_LARAVEL_ECHO_HOST,
            logToConsole: true,
            auth: {
              headers: {
                Authorization: "Bearer " + token
              }
            }
          })

          console.log('set echo token');
          Vue.prototype.$echo = echo_instance
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &#login-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 2% auto 0;
      width: 100%;
      max-width: 30rem;
      background-color: #24292e;

      .logo {
        width: 100%;
        height: 100px;
        margin: 3rem 1rem;
        background-image: url('/img/logo_long.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .input-group {
        padding: .25rem 3rem;
        margin: .25rem 0 .75rem;
        width: 100%;

        @media (max-width: 576px) {
          padding: .25rem 1.5rem;
        }

        label {
          color: rgba(255, 255, 255, 0.5);
          padding-left: .5rem;
        }

        .form-control {
          width: 100%;
          height: 3.5rem;
          color: #ffffff;
          background-color: #1c2126;
          padding: .5rem 1rem .5rem 1.5rem;
          margin-top: .25rem;
          border: 1px solid rgba(255, 255, 255, .2);
          border-radius: .75rem;
          box-shadow: 0 11px 23px rgba(0,0,0,.02);
          outline: none;
        }

        // input:-webkit-autofill {
        //   border: 0;
        //   -webkit-text-fill-color: #ffffff;
        //   background-color: #1c2126 !important;
        //   transition: background-color 5000s ease-in-out 0s;
        // }

        // @-webkit-keyframes autofill {
        //   to {
        //     background-color: #1c2126;
        //   }
        // }

        // input:-webkit-autofill {
        //   -webkit-animation-name: autofill;
        //   -webkit-animation-fill-mode: both;
        // }

        .invalid-feedback {
          width: 100%;
          padding-left: .5rem;
          font-size: 12px;
          color: #dc3545;
        }
      }

      .submit,
      .load {
        display: inline-flex;
        min-width: 10rem;
        min-height: 3.5rem;
        padding: .5rem 2.5rem;
        margin: 1.75rem auto 3rem;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 600;
        background-color: #0071e3;
        border: none;
        border-radius: .75rem;
      }

      .load {
        width: 10rem;
        height: 3rem;

        svg {
          width: 100%;
          height: 100%;
          animation:spin 1s linear infinite;

          @keyframes spin {
            100% {
              transform:rotate(360deg);
            }
          }
        }
      }
    }
  }
}
</style>
